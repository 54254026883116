import React, { Component } from "react";
import SwaggerInit from "./api/SwaggerUi"
import Navigation from './Navigation'
import { RedocStandalone } from 'redoc'
import { connect } from "react-redux";
import $ from "jquery";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNightEighties as style } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import RedocRestyle from './api/RedocRestyle'


const curlGetToken = String.raw`
curl --request POST \
  --url https://connect-to.neterium.cloud/demo/token \
  --header 'content-type: application/x-www-form-urlencoded' \
  --data 'username=REPLACE_USERNAME' \
  --data 'password=REPLACE_PASSWORD' \
  --data 'grant_type=password' \
  --data 'client_id=api'`;

const curlRefreshToken = String.raw`
curl --request POST \
  --url https://connect-to.neterium.cloud/demo/token \
  --data 'refresh_token=REPLACE_REFRESH_TOKEN' \
  --data 'grant_type=refresh_token' \
  --data 'client_id=api'`;


const redocOpts = {
    noAutoAuth: true,
    hideDownloadButton: true,
//    scrollYOffset: "76",
    disableSearch: true,
    theme: { colors: { primary: { main: '#142392' } } }
}

//const Swagger = (APIspec) => <SwaggerUI url="" spec={APIspec}/>



const Redoc = (APIspec) => (<RedocStandalone spec={APIspec} options={redocOpts} onLoaded={error => {
                                RedocRestyle()
                                SwaggerInit(APIspec)
                                $(".api-content").children(":first").attr("id","api-spec")
                                $(".download-spec").appendTo($(".api-info"))
                                $("#usage-info").appendTo($(".api-info"))

                                $(".menu-content").find("ul:first").prepend($("#custom-menu").find("li"))
                                $("#tag\\/screening").before($("#section-authentication"))
                                $(".custom-menu-element").click(function(event){
                                    var element = $(this).attr("data-item-id")
                                    document.getElementById(element).scrollIntoView()                                    
                                })
                                document.addEventListener("scroll", function(){
                                    $($(".scrollbar-container").find("li").get().reverse()).each(function(){
                                        if (document.getElementById($(this).attr("data-item-id")).getBoundingClientRect().top <= 0) {
                                            $(this).children("label").addClass("active")
                                            return false;
                                        } else {
                                            $(this).children("label").removeClass("active")
                                        }
                                    })

                                    $($(".scrollbar-container").find("label.active").get().reverse()).each(function(index){
                                        if (index > 0) {
                                            $(this).removeClass("active")
                                        }
                                    })
                                });
                               }}
                            />)

class APIs extends Component {

    render() {


        return  <div>
                    { (this.props.config !== null) ?
                        <div>
                            <div className="hidden">
                                        
                                <div id="custom-menu">
                                    <li className="custom-menu-element" data-item-id="api-spec">
                                        <label>Specification</label>
                                    </li>
                                    <li className="custom-menu-element" data-item-id="section-authentication">
                                        <label>Authentication</label>
                                    </li>
                                </div>

                                <div className="download-spec">
                                    <a className="btn btn-elegant" download="swagger.json" target="_blank" rel="noopener noreferrer" href={this.props.config.specUrl}>Download specification</a>
                                </div>
                                <div id="usage-info">
                                    <p>As a registered developer, you are welcome to try out our APIs, however access to the sandbox is restricted to 25 requests per day. If you want to test our solution more completely don't hesitate to contact us at <a href="mailto:usersuccess@neterium.io">usersuccess@neterium.io</a> to obtain an unlimited access to our API.</p>
                                </div>



                                <div id="section-authentication" className="api-auth">
                                    <div className="title">
                                        <h1>Authentication</h1>
                                    </div>
                                    <div className="block">
                                        <div className="doc">
                                            <p>The Neterium API's uses a <b>Bearer Authentication</b> scheme (also called token authentication) to authorize requests. <br/>The API client must obtain a <b>JWT token</b> from our Authentication service and add the <b>Authorization</b> header with each request sent to the API's:
                                            </p>
                                            <blockquote>Authorization: Bearer <i>REPLACE_BEARER_TOKEN</i></blockquote>
                                            <p>When requesting a new token with valid credentials, a new authentication session is created and the <i>get token</i> -response will contain an <b>access_token</b> with a limited lifetime which is defined by the key <b>expires_in</b> (in seconds). The same access token can be used with different requests during its lifespan.</p>
                                            <p>Before the access token expires, a new token should be retrieved. This can be achieved with the <b>refresh_token</b> included in the <i>get token</i> -response. The refresh token also has a limited lifetime, defined by <b>refresh_expires_in</b>, and can be used as long as the authentication session is valid. Once the refresh token has expired or the authentication session has been invalidated, a new session must be started by using the credentials.</p>
                                                <p>As a best practice, a separate thread or process should handle the authentication and automatically retrieve and refresh the tokens before expiration, so that this process can distribute a valid token that can be used with the API calls.</p>
                                                <p><b>Note:</b> Obtaining a token using the credentials with each API request should be avoided. Not only would this add extra lag for each request made, but this would also instantiate a new session in our Authentication service for each request and could unnecessarily overload our service, especially when performing many requests.</p>
                                        </div>

                                        <div className="token-example">
                                            <h3 className="text-white">Get Token</h3>
                                            <div className="code-block">
                                                <SyntaxHighlighter language="bash" style={style}>
                                                    {curlGetToken}
                                                </SyntaxHighlighter>
                                            </div>
                                            <h3 className="text-white">Renew Token</h3>
                                            <div className="code-block">
                                                <SyntaxHighlighter language="bash" style={style}>
                                                    {curlRefreshToken}
                                                </SyntaxHighlighter>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null
                    }
                    <Navigation showTitle={true} />
                    <main>
                        <div id="swagger"></div>
                        {
                            (this.props.APIspec !== null) ? Redoc(this.props.APIspec) : null
                        }
                    </main>
                </div>
    }
}

function mapStateToProps(state) {
  return {
    APIspec: state.api.APIspec,
    releaseNotes: state.api.releaseNotes,
    //user: state.oidc.user,
    config: state.api.config
  };
}

export default connect(mapStateToProps)(APIs);
